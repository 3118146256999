html {
  scroll-behavior: smooth;
}

/* Add specific styling for each section if needed */
#canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0; /* Set z-index to 0 as it was the last value in your HTML */
  pointer-events: none;
  background-color: transparent;
  width: 100% !important;
  z-index: 4;
}

#canvas3 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0; /* Set z-index to 0 as it was the last value in your HTML */
  pointer-events: none;
  background-color: transparent;
  width: 100% !important;
  z-index: 6;
}

#canvas2 {
  position: absolute;
  top: 100vh;
  left: 0;
  z-index: 0; /* Set z-index to 0 as it was the last value in your HTML */
  pointer-events: none;
  background-color: transparent;
  width: 100% !important;
}

#canvas4 {
  position: absolute;
  top: 100vh;
  left: 0;
  z-index: 0; /* Set z-index to 0 as it was the last value in your HTML */
  pointer-events: none;
  background-color: transparent;
  width: 100% !important;
}

#canvas5 {
  position: absolute;
  top: 200vh;
  left: 0;
  z-index: 0; /* Set z-index to 0 as it was the last value in your HTML */
  pointer-events: none;
  background-color: transparent;
  width: 100% !important;
  z-index: 6;
}

body {
  margin: 0px;
  background-color: black;
}

p {
  padding: 0rem;
  margin: 0rem;
}

.waves {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  background-color: #e5e5f7;
  opacity: 0.1;
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, rgba(36, 163, 167, 0.3215686275) 7.5px), repeating-linear-gradient(rgba(68, 235, 247, 0.3333333333), #44f744);
}

.grid {
  --s: 100px; /* control the size */
  --_g: #0000 90deg, rgba(243, 243, 243, 0.02) 0;
  background: conic-gradient(from 90deg at 2px 2px, var(--_g)), conic-gradient(from 90deg at 1px 1px, var(--_g));
  background-size: var(--s) var(--s);
  z-index: 2;
  position: fixed;
  width: 100%;
  height: 100%;
}

.blob1 {
  object-fit: contain;
  position: fixed;
  z-index: 3;
  background-image: url("./public/images/blob1.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-size: contain;
  width: 60rem;
  height: 60rem;
  top: -10vh;
  left: 40vw;
}

.blob2 {
  object-fit: contain;
  position: fixed;
  z-index: 3;
  background-image: url("./public/images/blob2.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-size: contain;
  width: 60rem;
  height: 60rem;
  top: 0vh;
  left: 30vw;
}

.blob3 {
  object-fit: contain;
  position: fixed;
  z-index: 3;
  background-image: url("./public/images/blob3.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-size: contain;
  width: 60rem;
  height: 60rem;
  top: 0vh;
  left: 5vw;
}

.blob4 {
  object-fit: contain;
  position: fixed;
  z-index: 3;
  background-image: url("./public/images/blob4.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-size: contain;
  width: 60rem;
  height: 60rem;
  top: -20vh;
  left: -10vw;
}

.filter {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25)) !important;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
}